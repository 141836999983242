import { defineStore } from 'pinia';
import { useAuthStore } from './auth';

export const useInformationsMarqueStore = defineStore({
  id: 'informationsMarque-store',
  state: () => {
    return {};
  },
  actions: {
    async update(item) {
      return await useApi.informationsMarque.update(item);
    },
  },
  getters: {
    infos() {
      return useAuthStore().workspace?.informations_marque || null;
    },

    typeConstructeur: (state) => {
      return state.infos?.type_constructeur || '';
    },

    raisonSociale: (state) => {
      return state.infos?.raisonSociale || '';
    },

    adresseComplete: (state) => {
      return state.infos?.adresse;
    },
    adresseCompleteFlat: (state) => {
      return state.infos?.adresse?.replace('\n', ' ');
    },

    adresseCommune: (state) => {
      const isThereANewLine = state.infos?.adresse?.includes('\n');
      return isThereANewLine
        ? state.infos?.adresse?.split('\n')[1].split(' ')[1]
        : state.infos?.adresse?.split(' ')[1];
    },
  },
});
